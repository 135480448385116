<template>
  <section class="min-w1600">
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <colgroup>
            <col width="10%"/>
            <col width="18%"/>
            <col width="18%"/>
            <col width="34%"/>
            <col width="10%"/>
            <col width="10%"/>
          </colgroup>
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-for="item in tableData" :key="item.level">
              <tr>
                <td class="roboto">{{item.level}}</td>
                <td>
                  <span v-if="!item.isActive" style="width:100%;"> </span>
                  <input v-else style="width:100%;" type="text"/>
                </td>
                <td>
                  <span v-if="!item.isActive" style="width:100%;"> </span>
                  <input v-else style="width:100%;" type="text"/>
                </td>
                <td>
                  <span v-if="!item.isActive" style="width:100%;" >{{item.domain}}</span>
                  <input v-else style="width:100%;" type="text" v-model="item.domain"  />
                </td>
                <td>
                  <ui-toggle :textType="'default'" :checked="item.useYn" @setYn="setUseYn(item)" />
                </td>
                <td>
                  <div class="status-change-btn-wrap">
                    <button v-if="!item.isActive" class="btn-innerTable btn-status-change wait" type="button" @click="updateBtnToggle(item)">{{ $t('button.modify') }}</button>
                    <button v-else class="btn-innerTable btn-status-change wait domainsave" type="button" @click="updateDomain(item)">{{ $t('button.save') }}</button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from "lodash";
import uiToggle from "@/components/ui/UiToggle";
import TableHead from "@/components/main/table/Head.vue";
import { getSiteData } from "@/libs/auth-helper";
import { settingLvDomain, settingLvDomainSave } from "@/api/setting";
import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
export default {
  name: "LevelDomainLevel",
  components: {
    TableHead,
    uiToggle
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ["userlv","totalCashInAmt","totalBetAmt02","site", "onstat", "option"]
      },
      tableData: [],
      siteInfo: {}
    };
  },
  methods: {
    getSiteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    },
    updateBtnToggle(item){
      item.isActive = !item.isActive
    },
    async updateDomain(item){
      const itemClone = lodash.cloneDeep(item);
      if (itemClone.useYn) {
        itemClone.useYn = "Y";
      } else {
        itemClone.useYn = "N";
      }
      const req = {
        siteId: this.siteInfo.siteId,
        level: itemClone.level,
        domain:itemClone.domain,
        useYn: itemClone.useYn
      };
      const res = await settingLvDomainSave(req);
      if (res.resultCode === "0") {
        alert("수정 완료");
        this.setTableData();
      } else {
        alert("수정 실패, 다시 시도해주세요.");
      }
    },
    async setUseYn(item) {
      item.useYn = !item.useYn;
      const itemClone = lodash.cloneDeep(item);
      if (itemClone.useYn) {
        itemClone.useYn = "Y";
      } else {
        itemClone.useYn = "N";
      }
      const req = {
        siteId: this.siteInfo.siteId,
        level: itemClone.level,
        domain:itemClone.domain,
        useYn: itemClone.useYn
      };
      const res = await settingLvDomainSave(req);
      if (res.resultCode === "0") {
        alert("수정 완료");
        this.setTableData();
      } else {
        alert("수정 실패, 다시 시도해주세요.");
      }
      this.setTableData();
    },
    async getDomainData() {
      const req = {
        siteId: this.siteInfo.siteId
      };
      const res = await settingLvDomain(req);
      return await res.data;
    },
    async setTableData() {
      const data = await this.getDomainData();
      if (data.list.length !== 0) {
        data.list.sort((a, b) => {
          a = a.level.toLowerCase();
          b = b.level.toLowerCase();
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
        for (const item of data.list) {
          if (item.useYn === "Y") {
            item.useYn = true;
          } else {
            item.useYn = false;
          }
          item.isActive = false;
        }
      }
      this.tableData = data.list;
      console.log(this.tableData);
    }
  },
  async created() {
    this.siteInfo = this.getSiteInfo();
    this.setTableData(1);
  }
};
</script>

<style scoped>
  .main-contents-wrapper{
    width: 100%;
    box-sizing: border-box;
  }
</style>
